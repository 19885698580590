import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  console.log('[guardCustom] protections')
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (
      visitor_role === 'host' ||
      visitor_role === 'client' ||
      visitor_role === 'security'
    ) {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}

export default [
  {
    path: '/reporting/visitor',
    name: 'reporting-visitor',
    component: () => import('@/views/reporting/index.vue'),
    meta: {
      pageTitle: 'Data Reporting',
      breadcrumb: [
        {
          text: 'reporting',
          active: true
        },
        {
          text: 'visitor',
          active: true
        }
      ],
      navActiveLink: 'reporting-visitor',
      guard: (next) => guardCustom(next, ['all'], ['reporting'])
    }
  }
]
