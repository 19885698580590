import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    console.log('visitor_ability:', visitor_ability)
    if (visitor_role === 'security' || visitor_role === 'host') {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}

export default [
  {
    path: '/scanning',
    name: 'scanning',
    component: () => import('@/views/scanning/index.vue'),
    meta: {
      layout: 'full',
      guard: (next) => guardCustom(next, ['all'], ['scan-visitor'])
    }
  }
]
