export default {
  // Endpoints
  loginEndpoint: 'auth/visitor/signin',
  registerEndpoint: 'auth/visitor/signup',
  refreshEndpoint: 'auth/visitor/refreshToken',
  logoutEndpoint: 'auth/visitor/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessTokenVisitor',
  storageRefreshTokenKeyName: 'refreshTokenVisitor'
}
