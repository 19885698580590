import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    console.log('visitor_ability:', visitor_ability)
    if (visitor_role === 'client' || visitor_role === 'host') {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}

export default [
  {
    path: '/qrcode-controll/visitor-umum',
    name: 'visitor-umum',
    component: () => import('@/views/qrcode-controll/visitor-umum/index.vue'),
    meta: {
      pageTitle: 'QRCode Controll',
      breadcrumb: [
        {
          text: 'visitor umum',
          active: true
        },
        {
          text: 'Manage akun aktif/non aktif',
          active: true
        }
      ],
      navActiveLink: 'visitor-umum',
      guard: (next) => guardCustom(next, ['all'], ['visitor-umum'])
    }
  },
  {
    path: '/qrcode-controll/visitor-khusus',
    name: 'visitor-khusus',
    component: () => import('@/views/qrcode-controll/visitor-khusus/index.vue'),
    meta: {
      pageTitle: 'QRCode Controll',
      breadcrumb: [
        {
          text: 'visitor khusus',
          active: true
        },
        {
          text: 'Manage akun aktif/non aktif',
          active: true
        }
      ],
      navActiveLink: 'visitor-khusus',
      guard: (next) => guardCustom(next, ['all'], ['visitor-khusus'])
    }
  }
]
