import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn
} from '@/auth/utils'

const guard = (next) => {
  if (!isUserLoggedIn()) {
    return next()
  }
  return next(getHomeRouteForLoggedInUser(getUserData().visitor_role))
}
import store from '@/store' // Adjust the path as needed

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
      // redirectIfLoggedIn: true
    }
  },
  {
    path: '/register/success',
    name: 'auth-register-success',
    component: () => import('@/views/components/RegisterSuccess.vue'),
    meta: {
      layout: 'full'
    },
    beforeEnter: (to, from, next) => {
      if (
        store.state.visitor.successData &&
        (store.state.visitor.successData.full_name ||
          store.state.visitor.successData.phone)
      ) {
        console.log('state', store.state.visitor.successData)
        next() // Allow navigation if data exists
      } else {
        console.log('state', store.state.visitor.successData) // Log in case of failure
        next({ path: '/error-404' }) // Redirect to an error page if data is missing
      }
    }
  }
].map((route) => {
  route.meta = route.meta ? { ...route.meta, guard } : { guard }
  return route
})
