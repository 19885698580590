export default [
  // {
  //   path: '/sa/guide',
  //   name: 'sa-guide',
  //   component: () => import('@views/pages/faq/Faq.vue')
  // },
  // {
  //   path: '/change/password',
  //   name: 'change-password',
  //   component: () => import('@views/pages/authentication/ResetPassword-v2.vue')
  // },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue')
  }
]
