import mock from '@/@fake-db/mock'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    {
      id: 1,
      url: '',
      title: 'Simon - KT 1503 DA',
      start: date,
      end: nextDay,
      allDay: false,
      extendedProps: {
        calendar: '[ A.1 ] Tamu ( Visitor ) - Umum'
      }
    },
    {
      id: 2,
      url: '',
      title: 'Agus Handoko',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: '[ B ] Identitas / Karyawan / Rutinitas'
      }
    },
    {
      id: 3,
      url: '',
      title: 'Yogi Arif Widodo',
      allDay: true,
      start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
      extendedProps: {
        calendar: '[ B ] Identitas / Karyawan / Rutinitas'
      }
    },
    {
      id: 4,
      url: '',
      title: 'Joko Wr king',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: '[ A.2 ] Awak 2 Truck'
      }
    },
    {
      id: 5,
      url: '',
      title: 'Rossi',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: '[ C ] Pekerja Harian'
      }
    },
    {
      id: 6,
      url: '',
      title: 'Jack Arrow - TB GOLDEN',
      start: date,
      end: nextDay,
      allDay: false,
      extendedProps: {
        calendar: '[ D ] Pengurus Agent BBM'
      }
    },
    {
      id: 7,
      url: '',
      title: 'Leader',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: '[ D ] Pengurus Agent BBM'
      }
    },
    {
      id: 8,
      url: '',
      title: 'William - NAYAKA',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: '[ E ] Jalur Laut ( Crew Kapal )'
      }
    },
    {
      id: 9,
      url: '',
      title: 'Secret',
      start: nextMonth,
      end: nextMonth,
      allDay: true,
      extendedProps: {
        calendar: 'Jalur Laut ( Crew )'
      }
    },
    {
      id: 10,
      url: '',
      title: 'HRD',
      start: prevMonth,
      end: prevMonth,
      allDay: true,
      extendedProps: {
        calendar: '[ B ] Identitas / Karyawan / Rutinitas'
      }
    }
  ]
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply((config) => {
  // Get requested calendars as Array
  const calendars = config.params.calendars.split(',')
  const output = data.events.filter((event) =>
    calendars.includes(event.extendedProps.calendar)
  )
  console.log('output:', output)

  return [
    200,
    data.events.filter((event) =>
      calendars.includes(event.extendedProps.calendar)
    )
  ]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/events').reply((config) => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply((config) => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find((e) => e.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply((config) => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const eventIndex = data.events.findIndex((e) => e.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
