export default {
  namespaced: true,
  state: {
    successData: null,
    tokenNotification: '' /* registration token subscribe fcm to topic */
  },
  getters: {
    getTokenNotification: (state) => state.tokenNotification
  },
  mutations: {
    SET_SUCCESS_DATA(state, val) {
      state.successData = val
    },
    /* usage : this.$store.state.visitor.tokenNotification */
    UPDATE_TOKEN_NOTIFICATION(state, val) {
      state.tokenNotification = val
    }
  },
  actions: {}
}
