import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// pages
import './data/pages/knowledge-base'
import './data/pages/profile-data'
import './data/apps/calendar'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
