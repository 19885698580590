import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (['superadmin', 'host'].includes(visitor_role)) {
      // allow click menu
      let flagContinue = false
      visitor_ability.map((a) => {
        if (flagContinue === false) {
          flagContinue = ['user', 'all'].includes(a.subject)
        }
        return a
      })
      if (flagContinue) {
        return next()
      }
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/sa/users',
    name: 'sa-users',
    component: () => import('@/views/superadmin/Users.vue')
  }
].map((route) => {
  // eslint-disable-next-line no-param-reassign
  route.meta = { guard }
  return route
})
