import { getUserData, isUserLoggedIn } from '@/auth/utils'
// Import the Vuex store
import store from '@/store' // Adjust the path as needed

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  console.log('[guardCustom] protections')
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (
      visitor_role === 'security' ||
      visitor_role === 'host' ||
      visitor_role === 'client'
    ) {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}

export default [
  {
    path: '/monitoring-analytic/planning',
    name: 'analytic-planning',
    component: () => import('@/views/monitoring-analytic/planning/index.vue'),
    meta: {
      pageTitle: 'Planning',
      breadcrumb: [
        {
          text: 'Monitoring Analytic',
          active: true
        },
        {
          text: 'Planning',
          active: true
        },
        {
          text: 'Realtime Monitoring Area PT SAL',
          active: true
        }
      ],
      navActiveLink: 'analytic',
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'user'],
          [
            'visitor-umum',
            'visitor-khusus',
            'create-form-khusus',
            'scan-visitor'
          ]
        )
    }
  },
  {
    path: '/monitoring-analytic/in-area',
    name: 'analytic-inarea',
    component: () => import('@/views/monitoring-analytic/inarea/index.vue'),
    meta: {
      pageTitle: 'IN Area',
      breadcrumb: [
        {
          text: 'Monitoring Analytic',
          active: true
        },
        {
          text: 'IN Area',
          active: true
        },
        {
          text: 'Realtime Monitoring Area PT SAL',
          active: true
        }
      ],
      navActiveLink: 'analytic',
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'user'],
          [
            'visitor-umum',
            'visitor-khusus',
            'create-form-khusus',
            'scan-visitor'
          ]
        )
    }
  },
  {
    path: '/monitoring-analytic/out-area',
    name: 'analytic-outarea',
    component: () => import('@/views/monitoring-analytic/outarea/index.vue'),
    // component: () => import('@/views/components/ComingSoon.vue'),
    meta: {
      pageTitle: 'OUT Area',
      breadcrumb: [
        {
          text: 'Monitoring Analytic',
          active: true
        },
        {
          text: 'OUT Area ( Sudah berkunjung )',
          active: true
        }
      ],
      navActiveLink: 'analytic',
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'user'],
          [
            'visitor-umum',
            'visitor-khusus',
            'create-form-khusus',
            'scan-visitor'
          ]
        )
    }
  }
]
