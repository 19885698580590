/* eslint-disable camelcase */
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import auth from './routes/auth'
import security from './routes/security'
import visitor from './routes/visitor'
import miscellaneous from './routes/miscellaneous'
import qrcodecontroll from './routes/qrcodecontroll'
import router_superadmin from './routes/router_superadmin'
import monitoring from './routes/monitoring'
import reporting from './routes/reporting'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'home'
      }
    },
    ...router_superadmin,
    ...auth,
    ...reporting,
    ...monitoring,
    ...security,
    ...visitor,
    ...qrcodecontroll,
    ...miscellaneous,
    { path: '*', redirect: 'error-404' }
  ]
})

router.beforeEach((to, from, next) => {
  // #TODO IMPLEMENTATION AUTHENTICATION WITH BE
  if ('guard' in to.meta) {
    return to.meta.guard(next)
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
